@use '~@nfs/ui-kit/src/theme/color';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
}
.title {
    font-weight: bold;
    font-size: 24px;
}
.contactPanel {
    background: #f9f9f9;
    .contactHeader {
        padding-bottom: 16px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid #cccccc;
    }
}
.contactIcon {
    color: color.$ICY_BLUE;
}
.static {
    width: 100%;
    flex-direction: column;
    align-items: normal;
    bottom: 0;
    padding-top: 0;
}
.link {
    font-size: 14px;
}
.infoText {
    font-size: 14px;
    color: #6c6c84;
}
